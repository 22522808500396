<template>
  <va-card title="Profile">
    <va-list
      fit
      class="mb-2"
    >
      <va-input
        label="User ID"
        class="hidden"
        v-model="$store.state.app.user.uid"
        :disabled="true"
      >
        <va-icon
          slot="prepend"
          color="gray"
          name="fa fa-id-badge"
        />
      </va-input>
      <va-list-label>
        Notification Preferences
      </va-list-label>
      <template
        v-for="(option,i) in notificationOptions"
      >
        <va-item
          clickable
          @click="toggleNotifications(i,option)"
        >
          <va-item-section>
            <div>
              <va-item-label>
                <div>
                  <span class="mr-4">
                    <va-icon
                      v-if="option"
                      color="gray"
                      name="fa fa-bell"
                    />
                    <va-icon
                      v-if="!option"
                      color="red"
                      name="fa fa-bell-slash"
                    />
                  </span>
                  <span>{{ capitalizeFirstLetter(i) }}</span>
                </div>
                <!-- <va-toggle
                  v-model="preferenceValue"
                  :label="name"
                /> -->
              </va-item-label>
            </div>
          </va-item-section>
        </va-item>
      </template>
    </va-list>
  </va-card>
</template>

<script>
export default {
  data() {
    return {
      notificationOptions:
        {'editor': false,
          'organization': false,
          'user': false,
          'system': false,
          'support': false,
          'quote': false,}
      ,
    };
  },
  created() {
    this.$db.collection('adminNotificationPreferences').doc(this.$store.state.app.user.uid).get().then((snapshot)=>{
      const data = snapshot.data();
      this.notificationOptions = {...this.notificationOptions, ...data};
    });
  },
  methods: {
    toggleNotifications(parameter, value) {
      this.notificationOptions[parameter] = !value;
      this.$db.collection('adminNotificationPreferences').doc(this.$store.state.app.user.uid).set(this.notificationOptions).then(() => {
        this.$store.dispatch('fetchNotifications');
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

  },

};
</script>

<style>

</style>
